import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { zeroAddress } from 'viem';

interface RankData {
  address: string;
  rank: number;
  time: number;
  tokenId: string;
  streak?: number;
  createdAt: string;
}

const fetcher = async (track: string, address: `0x${string}`): Promise<{ rows: RankData[] }> => {
  const data = await fetch(
    process.env.NEXT_PUBLIC_PONDER_URL + '/leaderboard/' + track + '?address=' + address,
    {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'GET',
    }
  );
  const json = await data.json();
  return json.rows;
};

const useLeaderboard = (
  track: string,
  address: `0x${string}` | undefined,
  queryOptions: Omit<UseQueryOptions, 'queryKey' | 'select'>
) => {
  return useQuery({
    queryKey: [['leaderboard'], [track]],
    queryFn: () => fetcher(track, address || zeroAddress),
    ...queryOptions,
  });
};

export default useLeaderboard;
export { fetcher as fetchLeaderboard, type RankData };
