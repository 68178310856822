function toCamelCase(str: string) {
  return str.replace(/([-_][a-z])/g, (group: string) =>
    group.toUpperCase().replace('-', '').replace('_', '')
  );
}

export function deepCamelCaseKeys(
  obj: unknown
): Record<string, unknown> | Record<string, unknown>[] | unknown {
  if (Array.isArray(obj)) {
    return obj.map((v) => deepCamelCaseKeys(v));
  }

  if (typeof obj === 'object' && obj !== null) {
    return Object.entries(obj).reduce(
      (a, [key, value]) => ({
        ...a,
        [toCamelCase(key)]: deepCamelCaseKeys(value),
      }),
      {}
    );
  }

  return obj;
}
