'use client';
import sdk from '@farcaster/frame-sdk';
import dynamic from 'next/dynamic';
import { useEffect, useState } from 'react';

import { RaceAd } from '../app/[s]/page';
import { RankData } from './Leaderboard';
import LeaderboardDrawer from './LeaderboardDrawer';
import LeaderboardSelector from './LeaderboardSelector';

const Providers = dynamic(() => import('../providers'));
const DrawerLogo = dynamic(() => import('./DrawerLogo'));
const Menu = dynamic(() => import('./Menu'));
const Seed = dynamic(() => import('./Seed'));
const Track = dynamic(() => import('./Track'));

const PlayPage = ({
  currentId,
  seed,
  updatedAtBlock,
  d,
  theme,
  ads,
  leaders,
}: {
  currentId: bigint;
  seed: bigint;
  updatedAtBlock: bigint;
  d: string;
  theme: any;
  ads: readonly RaceAd[];
  leaders: RankData[];
}) => {
  const [isSDKLoaded, setIsSDKLoaded] = useState(false);

  useEffect(() => {
    const load = async () => {
      sdk.actions.ready({
        disableNativeGestures: true,
      });
    };
    if (sdk && !isSDKLoaded) {
      setIsSDKLoaded(true);
      load();
    }
  }, [isSDKLoaded]);

  return (
    <Providers>
      <div className="max-h-[100dvh] flex flex-col items-center overflow-hidden">
        <DrawerLogo seed={BigInt(seed)} today={currentId} />
        <LeaderboardDrawer seed={seed?.toString()} updatedAt={updatedAtBlock} leaders={leaders} />

        <div
          className={`bottom-1/2 hidden lg:flex absolute left-0 md:top-1/2 md:w-1/4 md:min-w-[362px] md:h-auto flex-col justify-center`}
        >
          <div className="bg-white py-3 rounded-md z-[12] md:border-2 border-black">
            <p className="px-4 text-3xl">🏁 Leaderboard 🏆</p>
            <hr className="mx-4 my-1" />
            <LeaderboardSelector
              seed={seed?.toString()}
              updatedAt={updatedAtBlock}
              leaders={leaders}
            />
          </div>
        </div>

        <div className="h-[100dvh] w-[100vw] md:w-auto max-w-[100vw] relative md:aspect-[0.616]">
          <Track seed={seed?.toString()} track={d} key={seed?.toString()} theme={theme} ads={ads} />
        </div>

        <div className="hidden lg:flex lg:absolute lg:top-0 bottom-0 lg:w-1/4 lg:h-auto flex-col justify-center lg:right-0 ">
          <Menu current={BigInt(seed)} today={currentId} />
        </div>

        <Seed seed={seed?.toString()} key={`tr-${seed?.toString()}`} />
      </div>
    </Providers>
  );
};

export default PlayPage;
