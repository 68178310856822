'use client';
/**
 * v0 by Vercel.
 * @see https://v0.dev/t/fSoyUxm92kl
 */

import { useAccount } from 'wagmi';

import useLeaderboard from '../queries/leaderboardQuery';
import { deepCamelCaseKeys } from '../utils/camelCase';
import EmptyRow from './EmptyRow';
import LeaderboardRow from './LeaderboardRow';

export interface RankData {
  address: string;
  rank: number;
  time: number;
  tokenId: string;
  streak?: number;
  createdAt: string;
}

export const fillBoard = (winners: (RankData | null)[] = [], desiredLength: number) => {
  winners.length = desiredLength;
  winners.fill(null, winners.length - desiredLength + winners.filter(Boolean).length);
  return winners;
};

export default function Leaderboard({
  seed,
  initialData,
}: {
  seed: string;
  initialData?: RankData[];
}) {
  const { address } = useAccount();
  const { data: _data, isLoading } = useLeaderboard(seed, address, {
    refetchInterval: 60_000,
    initialData: deepCamelCaseKeys(initialData),
  });

  const data = _data as RankData[];

  const winners = fillBoard(data, 9);

  return (
    <div className="w-full bg-white">
      <div>
        {winners?.map((winner, i) =>
          winner ? (
            <LeaderboardRow key={`${winner.tokenId}-${winner.time}`} winner={winner} />
          ) : (
            <EmptyRow key={i} />
          )
        )}

        {!isLoading && !data?.length && <p className="text-center text-gray-500">[No winners]</p>}
        {isLoading && !data?.length && (
          <p className="text-center text-gray-500">[Loading leaderboard...]</p>
        )}
      </div>
    </div>
  );
}
